/* VARIABLES */
$pure-white: #FFF;
$primary-blue: #337799;
$dark-blue-accent: #001934;
$prominent-text: #AAA;
$normal-text: #888;
$title-shadow: 0 0 10px rgba(255, 255, 255, 0.75);

.app-nav {
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    width: 100%;
    height: 80px;
    display: flex;
    flex-direction: row;
}

.app-nav-logo-box {
    width: 80px;
    pointer-events: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.app-nav-logo {
    height: 52px;
    width: 47px;
}

.app-nav-title-box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 10px;
}

.app-nav-title {
    color: $pure-white;
    text-shadow: $title-shadow;
    animation: pulse 2s ease-in-out infinite;
}

.header-welcome-text {
    stroke-dasharray: 500;
    stroke-dashoffset: 500;
    animation: dash 15s linear infinite;
}

.header-welcome-message {
    color: transparent;
    animation: filltext 15s ease-in-out infinite;
}

.header-bottom-bar {
    position: absolute;
    z-index: 1;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 10%;
    background: linear-gradient(to bottom, transparent, black);
}

.header-welcome-image {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-220px, -110%);
    opacity: 0;
    animation: logodisappear 15s ease-in-out infinite;
    height: 52px;
    width: 47px;
}

.header-welcome-boxes {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(210px, 65px);
    height: 100px;
    width: 100px;
    opacity: 0;
    animation: boxesdisappear 15s ease-in-out infinite;
}

.header-welcome-box-1 {
    position: absolute;
    top: 0;
    left: 0;
    height: 10px;
    width: 10px;
    background-color: $primary-blue;
}

.header-welcome-box-2 {
    position: absolute;
    top: 2px;
    left: 16px;
    height: 8px;
    width: 8px;
    background-color: $primary-blue;
}

.header-welcome-box-3 {
    position: absolute;
    top: -6px;
    left: 16px;
    height: 4px;
    width: 4px;
    background-color: $primary-blue;
}

.header-welcome-box-4 {
    position: absolute;
    top: -12px;
    left: 24px;
    height: 6px;
    width: 6px;
    background-color: $primary-blue;
}

.scroll-image {
    position: absolute;
    bottom: 70px;
    left: 50%;
    transform: translate(-50%, 50%);
    height: 57px;
    width: 26px;
    animation: scroller 2s ease-in-out infinite;
}

section,
.parallax-section {
    position: relative;
    display: flex;
    flex-direction: column;
    min-height: 50px;
    padding: 100px 20vw;
}

.intro-title {
    color: black;
    font-size: 64px;
    text-shadow: 0 0 5px $primary-blue;
}

.intro-text {
    color: #AAA;
    line-height: 3rem;
    /* -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: $pure-white; */
    opacity: 1;
    font-size: 20px;
    z-index: 2;
}

.intro-image {
    position: absolute;
    top: 0;
    right: 0;
    background-image: linear-gradient(to right, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.3)),
        url('../images/uas1.jpg');
    ;
    background-position: left 50% center;
    width: 100%;
    height: 100%;
    background-size: contain;
    background-repeat: no-repeat;
}

.mission-section {
    background-color: $primary-blue;
    padding: 0 20vw;
}

.mission-title {
    color: $primary-blue;
    font-size: 64px;
    text-shadow: 0 0 10px #000;
    margin: 0;
}

.mission-text {
    color: #000;
    font-size: 20px;
}

.section-divider {
    aspect-ratio: 960/300;
    width: 100%;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}

.gradient1 {
    background-image: url("../images/svg/gradient1.svg");
    background-repeat: no-repeat;
    background-size: cover;
}

.layer1 {
    background-image: url("../images/svg/layer1.svg");
}

.layer2 {
    background-image: url("../images/svg/layer2.svg");
}

.layer3 {
    background-image: url("../images/svg/layer3.svg");
}

.layer4 {
    background-image: url("../images/svg/layer4.svg");
    background-repeat: no-repeat;
    background-size: cover;
}

.layer5,
.layer6 {
    background-image: url("../images/svg/layer5.svg");
    background-repeat: no-repeat;
    background-size: cover;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 5;
    height: 200px;
    pointer-events: none;
}

.layer6 {
    background-image: url("../images/svg/layer6.svg");
}

.service-section {
    padding: 0px 20vw;
}

.service-heading {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.service-title {
    font-size: 64px;
    text-shadow: 0 0 10px $primary-blue, 0 0 5px $dark-blue-accent;
}

.service-text {
    color: $primary-blue;
    font-size: 20px;
    margin-bottom: 5px;
}

.service-text-bottom {
    margin-bottom: 30px;
}

.service-box {
    flex-direction: row;
    display: flex;
    flex: 1;
}

.service-block,
.team-member-card,
.partner-card {
    flex: 1;
    /* background: linear-gradient(-45deg, rgba(0, 0, 0, 0.22), rgba(51, 119, 153, 0.25)); */
    background: black;
    box-shadow: 12px 12px 16px 0 rgba(0, 25, 52, 0.35), -8px -8px 12px 0 rgba(51, 119, 153, 0.3);
    border-radius: 35px;
    display: flex;
    flex-direction: column;
    min-height: 200px;
    margin: 1rem;
}

.team-member-card
{
    min-height: 515px;
}

.service-block-header {
    background-color: $dark-blue-accent;
    border-top-left-radius: 35px;
    border-top-right-radius: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 20px;
    min-height: 80px;
    position: relative;
}

.service-block-header-text {
    color: $pure-white;
    font-size: 18px;
}

.service-block-header-image {
    position: absolute;
    left: 10px;
    bottom: -40px;
}

.service-block-header-tag {
    position: absolute;
    left: 80px;
    bottom: -30px;
    font-size: 12px;
    color: $primary-blue;
    text-align: left;
}

.service-block-detail {
    display: flex;
    padding-top: 40px;
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 10px;
    min-height: 200px;
    position: relative;
    color: $prominent-text;
    font-size: 14px;
    text-align: justify;
}

.parallax-section {
    min-height: 1000px;
    overflow: hidden;
    margin: 0;
    padding: 0;
}

.parallax-section-inner {
    min-height: 1000px;
}

.parallax-section::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: inherit;
    transform-origin: center;
    transform: translateZ(-1px) scale(2);
    perspective: 1px;
    mix-blend-mode: screen;
}

.parallax-video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    transform: translate3d(0, 0, 0);
}

.parallax-video::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    mix-blend-mode: multiply;
}

.team-member-card {
    justify-content: center;
    align-items: center;
    padding: 20px;
    background-color: rgba(0, 0, 0, 0.9);
}

.partner-card {
    padding: 20px;
    flex-direction: row;
    align-items: center;
}

.partner-logo {
    margin-right: 20px;
}

.contact-text,
.team-member-text,
.team-member-name-text,
.partner-text,
.partner-link {
    color: $normal-text;
    font-size: 14px;
    text-align: justify;
    margin: 30px 0;
}

.team-member-text,
.team-member-name-text {
    text-align: center;
    margin: 0;
}

.team-member-name-text {
    font-size: 18px;
    color: $pure-white;
    margin-top: 15px;
}

.partner-text-container {
    display: flex;
    flex-direction: column;
    align-items: left;
}

.partner-text {
    margin-bottom: 0;
}

.partner-link {
    text-align: left;
}

.partner-link:hover {
    color: aqua;
    text-decoration: underline;
}

.contact-section {
    margin: 0;
    padding: 0;
}

.contact-title {
    color: $primary-blue;
    font-size: 36px;
    margin: 0px;
    text-align: left;
    text-shadow: 0 0 15px $primary-blue;
}

.social-container {
    display: flex;
    flex-direction: row;
    margin-top: 30px;
    align-items: center;
}

.social-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 48px;
    height: 48px;
    border-radius: 32px;
    border: 2px solid $primary-blue;
    box-shadow: 0 0 5px 5px rgba(51, 119, 153, 0.5);
}

.social-box {
    flex: 1;
    padding: 5px 20px;
    display: flex;
    flex-direction: column;
}

.social-title {
    color: $pure-white;
    font-size: 20px;
    text-align: left;
    padding: 0;
    margin: 0;
    text-shadow: 0 0 10px rgba(255, 255, 255, 0.75);
}

.social-detail {
    color: $normal-text;
    font-size: 14px;
    text-align: left;
    padding: 0;
    margin: 0;
    margin-top: 2px;
}

.social-call {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
}

.social-box-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    margin-top: auto;
}

.contact-form-title {
    color: $pure-white;
    font-size: 20px;
    text-align: left;
    padding: 0;
    padding-bottom: 20px;
    margin: 0;
    margin-left: 10px;
    text-shadow: 0 0 10px rgba(255, 255, 255, 0.75);
}

.contact-form-input-group {
    display: flex;
    flex-direction: column;
    margin-left: 10px;
}

.contact-form-input {
    background-color: #222;
    margin: 0;
    margin-top: 15px;
    padding: 15px;
    color: $pure-white;
    font-size: 14px;
    text-align: left;
    border-radius: 30px;
    border: 2px solid $primary-blue;
    box-shadow: 0 0 5px 3px rgba(51, 119, 153, 0.25);
}

.contact-form-btn-submit {
    margin: 0;
    margin-top: 25px;
    padding: 15px;
    color: $pure-white;
    font-size: 16px;
    text-align: left;
    border-radius: 30px;
    border: 2px solid $primary-blue;
    box-shadow: 0 0 5px 3px rgba(51, 119, 153, 0.5);
    cursor: pointer;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    max-width: 200px;
}

.contact-form-btn-submit:hover {
    border: 2px solid aqua;
}

.footer-section-divider {
    min-height: 15px;
    margin: 0;
    padding: 0;
    background-color: #111;
}

.footer-section {
    background-image: url('../images/footer-image-2.png');
    background-repeat: no-repeat;
    background-size: cover;
    padding: 10px 2vw;
}

.footer-title {
    color: $pure-white;
    font-size: 24px;
    text-align: left;
    padding: 0;
    margin: 0;
    margin-top: 30px;
    text-shadow: 0 0 10px rgba(255, 255, 255, 0.75);
    z-index: 3;
}

.footer-text {
    color: $prominent-text;
    font-size: 14px;
    text-align: justify;
    padding: 0;
    padding-top: 20px;
    margin: 0;
    margin-top: 2px;
}

/*
MEDIA QUERIES -----------------------------------------------------------------------
*/

@media (max-width: 1200px) {
    section {
        padding: 100px 10vw;
    }

    .service-section {
        padding: 0px 10vw;
    }

    .parallax-section {
        min-height: 1100px;
    }

    .parallax-section-inner {
        min-height: 1100px;
    }
}

@media (max-width: 800px) {
    section {
        padding: 100px 5vw;
    }

    .service-section {
        padding: 0px 5vw;
    }

    .service-box {
        flex-direction: column;
    }

    .service-block-detail {
        min-height: 50px;
    }

    .contact-title {
        text-align: center;
    }

    .social-container {
        flex-direction: column;
    }

    .social-title {
        text-align: center;
        padding-top: 10px;
    }

    .social-call {
        margin-top: 10px;
        margin-bottom: 20px;
    }

    .parallax-section {
        min-height: 1300px;
    }

    .parallax-section-inner {
        min-height: 1300px;
    }

    .layer6 {
        height: 100px;
    }

    .partner-card {
        flex-direction: column;
    }

    .partner-logo {
        margin: 0;
    }
}

@media (max-width: 600px) {
    section {
        padding: 100px 5px;
    }

    .service-section {
        padding: 0px 5px;
    }

    .service-box {
        flex-direction: column;
    }

    .service-block-detail {
        min-height: 50px;
    }

    .contact-title {
        text-align: center;
    }

    .social-container {
        flex-direction: column;
    }

    .social-title {
        text-align: center;
        padding-top: 10px;
    }

    .parallax-section {
        min-height: 1500px;
    }

    .parallax-section-inner {
        min-height: 1500px;
    }

    .layer6 {
        height: 100px;
    }

    .partner-card {
        flex-direction: column;
    }

    .partner-logo {
        margin: 0;
    }
}

/*
KEYFRAMES ---------------------------------------------------------------------------
*/

@keyframes pulse {
    0% {
        text-shadow: 0 0 5px $pure-white;
    }

    50% {
        text-shadow: 0 0 5px $primary-blue;
    }

    100% {
        text-shadow: 0 0 5px $pure-white;
    }
}

@keyframes dash {
    0% {
        stroke-dashoffset: 500;
    }

    10% {
        stroke-dashoffset: 500;
    }

    50% {
        stroke-dashoffset: 0;
    }

    90% {
        stroke-dashoffset: 500;
    }

    100% {
        stroke-dashoffset: 500;
    }
}

@keyframes filltext {
    0% {
        color: transparent;
    }

    50% {
        color: $pure-white;
    }

    100% {
        color: transparent;
    }
}

@keyframes logodisappear {
    0% {
        opacity: 0;
    }

    15% {
        opacity: 1;
    }

    50% {
        opacity: 1;
    }

    85% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

@keyframes boxesdisappear {
    0% {
        opacity: 0;
    }

    25% {
        opacity: 0;
    }

    50% {
        opacity: 1;
    }

    75% {
        opacity: 0;
    }

    100% {
        opacity: 0;
    }
}

@keyframes scroller {
    0% {
        bottom: 70px;
    }

    50% {
        bottom: 80px;
    }

    100% {
        bottom: 70px;
    }
}