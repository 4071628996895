/* VARIABLES */
$space: 150px;
$mercury-col-padding: 20px;
$mercury-col-width-small: 88%;
$mercury-border-color: rgba(51, 119, 153, 0.75);
$mercury-border-shadow: 0px 0px 3px 3px rgba(51, 119, 153, 0.5);
$mercury-overlay-opacity: 0.75;
$small-screen-max: 800px;
$mercury-cols: (
    30: mercury-col-30perc,
    40: mercury-col-40perc,
    50: mercury-col-50perc,
    60: mercury-col-60perc,
    70: mercury-col-70perc,
);

/* MIXINS */
@mixin mercury-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

@mixin mercury-row {
    flex: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

@mixin mercury-column($width) {
    width: $width;
    padding: $mercury-col-padding;
    background-color: transparent;
}

@mixin small-screen {

    .mercury-container-row,
    .mercury-container-row-align-start {
        flex-direction: column;
    }

    .mercury-container-overlay {
        padding: 0;
    }

    @each $size,
    $class in $mercury-cols {
        .#{$class} {
            width: $mercury-col-width-small;
            padding: $mercury-col-padding 5px;
            background-color: transparent;

            &.mercury-col-border-right::before {
                background-color: transparent;
                box-shadow: none;
            }
        }
    }
}

/* STYLES */
.mercury-container {
    @include mercury-container;

    &-bottom-margin {
        margin-bottom: $space;
    }

    &-overlay {
        padding: 0 20vw;
        position: absolute; 
        top: 0; 
        left: 0; 
        z-index: 1;
        margin-top: 100px;
    }

    &-row,
    &-row-align-start {
        @include mercury-row;

        &-align-start {
            align-items: start;
        }
    }
}

.mercury-col {
    @include mercury-column(100%);
}

$i: 30;

@while $i < 71 {
    .mercury-col-#{$i}perc {
        @include mercury-column($i * 1%);
    }

    $i: $i + 10;
}

.mercury-col-border-right {
    position: relative;

    &::before {
        content: "";
        position: absolute;
        right: -5px;
        width: 2px;
        height: 100%;
        background-color: $mercury-border-color;
        box-shadow: $mercury-border-shadow;
    }
}

.top-blend {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 100px;
    margin: 0;
    padding: 0;
    z-index: 1;
}

.top-blend-dark {
    background: linear-gradient(to bottom, #111, rgba(17, 17, 17, 0));
}

.dark-overlay {
    background-color: rgba(17, 17, 17, $mercury-overlay-opacity);
    border-radius: 5px;
}

img.avatar {
    border-radius: 50%;
}

/* MEDIA QUERIES */
@media (max-width: $small-screen-max) {
    @include small-screen;
}

@media (max-width: 600px) {
    @include small-screen;
}